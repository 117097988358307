import Head from "next/head"
import Link from "next/link"
// import PageTemplate from "../components/templates/PageTemplate"

const NotFound = () => {
  return (
    <>
      <Head>
        <title>Not Found</title>
      </Head>
      {/* <PageTemplate> */}
      <div className="flex justify-center flex-col h-screen">
        <div className="text-center">
          <p className="max-w-[30ch] mx-auto mb-4 mt-12">
            Siden findes desværre ikke. Hvis du har fulgt et link hertil, kan
            det være indholdet er blevet taget ned.
          </p>
          <Link href="/">
            <button className="btn">Back to home</button>
          </Link>
        </div>
      </div>
      {/* </PageTemplate> */}
    </>
  )
}

export default NotFound
